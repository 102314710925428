@import "styles/variables.module.scss";
.base {
  // ...

  @include mq(md) {
    // ...
  }
}

.normal {
  // composes: base;
}

.small {
  // composes: base;
  font-size: 0.75rem;
}
