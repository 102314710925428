@import "styles/variables.module.scss";
.header {
  position: fixed;
  top: 0;
  z-index: 50;
  width: 100%;
  background-color: white;
  transform: translateY(-25px);
  transition: transform $transition-duration;

  &.floating {
    transform: translateY(-25px);
  }

  @include mq(lg) {
    transform: none;
  }
}

.topBar {
  display: flex;
  gap: 64px;
  padding: 0.5rem 0;
  justify-content: flex-end;
  margin-right: 32px;
}

.todo {
  display: inline-block;
  font-size: 0.75rem;
  color: $primary-light;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  transition: color $transition-duration;
}

.logo {
  margin-bottom: 1rem;

  @include mq(md) {
    margin: 0;
  }
}

.linkList {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 1rem;
  padding-left: 2rem;
  text-align: left;
}

.linkWrapper {
  // tw="pl-4 first:p-0"
}

.link {
}

.localeSwitch {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.9em;
}

.burgerButton {
  @include mq(lg) {
    display: none;
  }
}

.burgerWrapper {
  position: fixed;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  // top: 68px;
  // top: 327px;
  // top: 120px;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #000a3a;
  color: white;
  z-index: 100;
  overscroll-behavior: contain;
  overflow: auto;
  padding: 16px 32px 64px;
}

.burgerContent {
  display: grid;
  gap: 64px;
}

.burgerList {
  display: grid;
  gap: 32px;

  > * {
    display: block;
    background-color: rgba(39, 40, 41, 0.07);
  }

  button {
    display: flex !important;
  }
}

.burgerActions {
  display: grid;
  gap: 1rem;

  > * {
    width: 100%;
  }
}

.burgerColumns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 0.5rem;
  background-color: white;
}
