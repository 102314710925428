@import "styles/variables.module.scss";
.accordion {
  position: relative;
  // border-bottom: 1px solid #ccc;

  &.isOpen {
    transition: all 1000ms;
  }

  &.isClosed {
    transition: all 300ms;
  }

  &.isOpen + &.isOpen {
    margin-top: 0em;
  }

  &.isClosed + &.isClosed {
    margin-top: 0em;
  }
}

.chevron {
  margin-left: 15px;
  transform-origin: center;
  transition: transform $transition-duration;
  color: inherit;

  &.chevronOpen {
    transform: scaleY(-1);
  }
}

.collapse {
  transition: height 460ms cubic-bezier(0.4, 0, 0.2, 1), opacity 0 0.2s,
    transform 0 0.2s;
  transition-duration: 0.4s;

  .isOpen & {
    opacity: 1;
    transform: none;
  }

  .isClosed & {
    opacity: 0;
    transform: translateY(10px);
  }
}

.header {
  border: none;
  display: flex;
  align-items: center;
  font-size: 100%;
  margin: 0;
  position: relative;
  width: 100%;
  text-align: left;
  background: transparent;
  outline: none;
  cursor: pointer;
  transition: background-color $transition-duration;

  color: #dedede;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  // &:hover,
  // &:focus {
  //   background-color: #f3f3f3;
  // }

  @include mq(md) {
    display: none;
  }

  .isOpen {
    min-height: 80px;
  }

  .isClosed {
    min-height: 50px;
  }

  > span {
    display: block;
    flex: 1;
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    color: #dedede;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
