@import "styles/variables.module.scss";
.wrapper {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  // ...

  @include mq(md) {
    // ...
  }
}

.title {
  color: #fff;
  // font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.listWrapper {
}

.itemsWrapper {
  display: grid;
  padding-top: 1rem;
}

.itemsWrappersingle {
  composes: itemsWrapper;
}

.itemsWrapperdouble {
  composes: itemsWrapper;
  grid-template-columns: 1fr 1fr;
}

.item {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  font-size: 15px;
  min-width: 180px;
  padding: 10px 20px;
  color: #f0f0f3;
  font-size: 14px;
}

.icon {
  width: 20px;
  height: 20px;
  // background-color: var(--color);
  // border-radius: 6px;
}

.iconWrapper {
  composes: icon;
  display: flex;
  justify-content: center;
  align-items: center;

  // > img {
  //   filter: invert(1);
  // }
}

.itemLink {
  display: grid;

  &:hover {
    color: $primary-light;
  }
}

.itemTitle {
  // color: #111111;
}

.description {
  color: #aaaaaa;
  font-size: 13px;
}

.divider {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  margin: 0;
  border: 0;
}
