@import "styles/variables.module.scss";
.wrapper {
  // ...

  @include mq(md) {
    // ...
  }
}

.portableText {
  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  p,
  ul,
  ol,
  blockquote {
    &:not(:first-child) {
      margin-top: 0.5em;
    }

    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }

  h2 {
    font-size: 1.5rem;

    &:not(:first-child) {
      margin-top: 1em;
    }

    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }

  h3 {
    font-size: 1.3rem;

    &:not(:first-child) {
      margin-top: 0.8em;
    }

    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }

  a {
    &:hover {
    }
  }

  ul {
    list-style: initial;
    list-style-position: inside;
  }
}
