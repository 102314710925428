@import "styles/variables.module.scss";
.wrapper {
  position: relative;
  display: none;
  font-size: 15px;

  @include mq(lg) {
    display: block;
  }
}

.dropdownWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}
